import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import useOutsideRef from "../hooks/useOutsideRef";
import { useHistory } from "react-router-dom";

const RegionIcon = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47917 0 0 4.47917 0 10C0 15.5208 4.47917 20 10 20C15.5208 20 20 15.5208 20 10C20 4.47917 15.5208 0 10 0ZM18.3542 13.75H15.0417C15.2708 12.7083 15.3958 11.6042 15.4167 10.4167H17.9167C18.1458 10.4167 18.3333 10.2292 18.3333 10C18.3333 9.77083 18.1458 9.58333 17.9167 9.58333H15.4167C15.3958 8.39583 15.2708 7.29167 15.0417 6.25H18.3333H18.3542C18.875 7.39583 19.1667 8.66667 19.1667 10C19.1667 11.3333 18.875 12.6042 18.3542 13.75ZM10.4167 19.125V14.5833H13.9375C13.2083 17.0625 11.8958 18.8333 10.4167 19.125ZM10.4167 13.75V10.4167H14.5833C14.5625 11.5833 14.4167 12.7292 14.1667 13.75H10.4167ZM1.64583 13.75C1.1875 12.7292 0.895833 11.6042 0.854167 10.4167H4.60417C4.625 11.5625 4.75 12.6875 4.97917 13.75H1.66667H1.64583ZM10 0.833333C11.6667 0.833333 13.1458 2.70833 13.9375 5.41667H10.4167V2.08333C10.4167 1.85417 10.2292 1.66667 10 1.66667C9.77083 1.66667 9.58333 1.85417 9.58333 2.08333V5.41667H6.0625C6.85417 2.70833 8.33333 0.833333 10 0.833333ZM14.1667 6.25C14.3958 7.27083 14.5417 8.41667 14.5833 9.58333H10.4167V6.25H14.1667ZM9.58333 6.25V9.58333H5.41667C5.4375 8.41667 5.58333 7.27083 5.83333 6.25H9.58333ZM5.41667 10.4167H9.58333V13.75H5.83333C5.58333 12.7083 5.45833 11.5833 5.41667 10.4167ZM17.9375 5.41667H14.8333C14.3542 3.625 13.5833 2.16667 12.625 1.22917C14.875 1.89583 16.7708 3.41667 17.9375 5.41667ZM7.375 1.22917C6.4375 2.1875 5.66667 3.64583 5.16667 5.41667H2.91667C2.6875 5.41667 2.5 5.60417 2.5 5.83333C2.5 6.0625 2.6875 6.25 2.91667 6.25H4.95833C4.72917 7.29167 4.60417 8.39583 4.58333 9.58333H0.833333C1.02083 5.625 3.72917 2.3125 7.375 1.22917ZM2.0625 14.5833H5.16667C5.25 14.9167 5.35417 15.25 5.47917 15.5625C5.5625 15.7708 5.79167 15.8958 6.02083 15.8125C6.22917 15.7292 6.35417 15.5 6.27083 15.2708C6.1875 15.0417 6.125 14.8125 6.04167 14.5833H9.58333V19.1458C6.375 19.0208 3.58333 17.2083 2.0625 14.5833ZM12.625 18.7708C13.5625 17.8125 14.3333 16.3542 14.8333 14.5833H17.9375C16.7708 16.5833 14.875 18.1042 12.625 18.7708Z"
        fill={color}
      />
    </svg>
  );
};
const ArrowIcon = (color) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4L0 0H8L4 4Z" fill={color} />
    </svg>
  );
};

const CustomSelect = (props) => {
  const history = useHistory();
  const selectRef = useRef(null);
  const [locale, setLocale] = useState("PT");
  const [active, setActive] = useState(false);

  const locales = [
    {
      id: 1,
      title: "PT",
    },
    {
      id: 2,
      title: "EN",
    },
    {
      id: 3,
      title: "ES",
    },
  ];

  const localesFiltered = locales.filter((item) => item.title !== locale);

  const activeColor = active ? "#fff" : "#2A3552";

  useOutsideRef(selectRef, () => setActive(false));

  useEffect(() => {
    history.replace({
      pathname: "/",
      search: `?l=${locale}`,
      state: { some: "state" },
    });
  }, [locale]);

  return (
    <Container ref={selectRef} background={active ? "#0056A1" : "#fff"}>
      <Content
        onClick={() => {
          setActive(!active);
        }}
      >
        {RegionIcon(activeColor)}
        <Label color={activeColor}>{locale}</Label>
        {ArrowIcon(activeColor)}
      </Content>
      <Collapse
        background={active ? "#0056A1" : "#fff"}
        maxHeight={active ? `100px` : `0px`}
      >
        {localesFiltered.map((item) => (
          <Option
            key={item.id}
            onClick={() => {
              setLocale(item.title);
              setActive(false);
            }}
          >
            {item.title}
          </Option>
        ))}
      </Collapse>
    </Container>
  );
};

export default CustomSelect;

const Container = styled.div`
  position: relative;
  width: 100px;
  background-color: ${(props) => props.background};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  z-index: 20;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
`;

const Label = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.color};
`;

const Collapse = styled.div`
  position: absolute;
  width: 100px;
  background-color: ${(props) => props.background};
  z-index: 10;

  max-height: ${(props) => props.maxHeight};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

const Option = styled.div`
  height: 38px;
  color: #fff;
  font-size: 0.75rem;
  padding-left: 2.9rem;
  display: flex;
  align-items: center;
`;
