import React, { useEffect } from "react";
import styled from "styled-components";

import Banner from "../components/Home/Banner";
import SectionMainFeatures from "../components/Home/SectionMainFeatures";
import InspiredOfFuture from "../components/Home/InspiredOfFuture";
import InspiredOfFutureDown from "../components/Home/inspiredOfFutureDown";
import NewBrandNewWay from "../components/Home/NewBrandNewWay";
import Video from "../components/Home/Video";
import OurPurpose from "../components/Home/OurPurpose";
import OurVision from "../components/Home/OurVision";

import BannerDesk from "../assets/images/Banner-1920.png";

import portuguese from "../json/pt-br.json";
import english from "../json/en-US.json";
import spanish from "../json/es-es.json";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

const Home = () => {
  const [language, setLanguage] = useState("PT");
  const location = useLocation();
  const [lang, setLang] = useState(portuguese);

  useEffect(() => {
    console.log("location", location);
    const query = queryString.parse(location.search);
    setLanguage(query.l);
    switch (query.l) {
      case "PT":
        setLang(portuguese);
        break;
      case "EN":
        setLang(english);
        break;
      case "ES":
        setLang(spanish);
        break;
    }
  }, [location]);

  return (
    <Container>
      <Banner lang={lang} />
      <InspiredOfFuture lang={lang} />
      <Video lang={language} />
      <NewBrandNewWay lang={lang} />
      <SectionMainFeatures lang={lang} />
      <InspiredOfFutureDown lang={lang} />
      <OurPurpose lang={lang} />
      <OurVision lang={lang} />
    </Container>
  );
};

export default Home;

const Container = styled.div`
  display: grid;
  /* row-gap: 54px; */
`;
