import React from "react";
import styled from "styled-components";

const Footer = (props) => {
  return (
    <Container>
      <Copyright>{props.lang.Copyright}</Copyright>
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 126px;
  background: #10213b;
  padding: 0 20px;
  margin-top: 48px;
`;

const Copyright = styled.span`
  max-width: 954px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-align: end;
`;
