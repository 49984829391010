import { useEffect } from "react";

function useOutsideRef(ref, callback) {
  useEffect(() => {
    function handleOutside(event) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("keyup", handleOutside);
    document.addEventListener("mouseover", handleOutside);
    return () => {
      document.removeEventListener("keyup", handleOutside);
      document.removeEventListener("mouseover", handleOutside);
    };
  }, [ref]);
}

export default useOutsideRef;
