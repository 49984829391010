import React from "react";
import { Route, Router, Switch } from "react-router-dom";

//components
import { Layout } from "../components/Layout";
import Home from "../pages/home";

const Routes = ({ history }) => (
  <Router history={history}>
    <Layout>
      <Switch>
        <Route exact path="/" component={Home} />
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
