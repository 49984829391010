import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes/index";
import history from "./utils/history";
import { ThemeProvider } from "styled-components";
import { ParallaxProvider } from "react-scroll-parallax";
import { theme } from "./theme";
import "./font.css";

ReactDOM.render(
  <>
    <ParallaxProvider>
      <ThemeProvider theme={theme}>
        <Routes history={history} />
      </ThemeProvider>
    </ParallaxProvider>
  </>,
  document.getElementById("root")
);

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept();
  }
}
