import React from "react";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { Parallax } from "react-scroll-parallax";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.css";
import Banner1920 from "../../assets/images/Banner-1920.png";
import Banner1440 from "../../assets/images/Banner-1440.png";
import Banner1280 from "../../assets/images/Banner-1280.png";
import Banner1024 from "../../assets/images/Banner-1024.png";
import Banner800 from "../../assets/images/Banner-800.png";
import Banner960 from "../../assets/images/Banner-960.png";
import Banner600 from "../../assets/images/Banner-600.png";
import Banner480 from "../../assets/images/Banner-480.png";
import Banner768 from "../../assets/images/Banner-768.png";
import Banner375 from "../../assets/images/Banner-375.png";

import BannerMobile from "../../assets/images/Banner-375.png";

const Banner = (props) => {
  return (
    <ContainerImage>
      <Content>
        <MyAnim animateIn="animate__fadeIn" delay={300}>
          <BoxOrangeLeft></BoxOrangeLeft>
          <TextBox>
            <TextTitle
              dangerouslySetInnerHTML={{
                __html: props.lang.BannerTitle1,
              }}
            />
            <OrangeText
              dangerouslySetInnerHTML={{
                __html: props.lang.BannerTitle2,
              }}
            />{" "}
            <SecondLine>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: props.lang.BannerFirstLine,
                }}
              />
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: props.lang.BannerSecondLine,
                }}
              />
            </SecondLine>
          </TextBox>
          <BoxOrangeRight></BoxOrangeRight>
        </MyAnim>
      </Content>
    </ContainerImage>
  );
};

export default Banner;

const ContainerImage = styled.div`
  position: relative;
  background-image: url(${Banner1920});
  background-repeat: no-repeat;
  width: 100%;
  height: 586px;
  background-size: 100% 586px;

  @media (max-width: 1440px) {
    background-image: url(${Banner1440});
    height: 452px;
    background-size: 100% 452px;
  }
  @media (max-width: 1280px) {
    background-image: url(${Banner1280});
    height: 402px;
    background-size: 100% 402px;
  }
  @media (max-width: 1024px) {
    background-image: url(${Banner1024});
    height: 321px;
    background-size: 100% 321px;
  }
  @media (max-width: 960px) {
    background-image: url(${Banner960});
    height: 585px;
    background-size: 100% 585px;
  }
  @media (max-width: 800px) {
    background-image: url(${Banner800});
    height: 610px;
    background-size: 100% 610px;
  }
  @media (max-width: 768px) {
    /* background-image: url(${Banner768}); */
    height: 512px;
    background-size: 100% 512px;
  }
  @media (max-width: 740px) {
    background-image: url(${Banner768});
    height: 512px;
    background-size: 100% 512px;
  }
  @media (max-width: 600px) {
    background-image: url(${Banner600});
    /* height: 488px;
    background-size: 100% 488px; */
  }
  @media (max-width: 480px) {
    background-image: url(${Banner480});
    height: 386px;
    background-size: 100% 386px;
  }
  @media (max-width: 375px) {
    background-image: url(${Banner375});
    height: 400px;
    background-size: 100% 400px;
  }
`;

const Content = styled.div`
  max-width: 954px;
  margin: 0 auto;
`;

const BoxText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 427px;
  height: 209px;
  background-color: #fff;
  position: absolute;
  margin-top: 70px;
  z-index: 99;

  @media (max-width: 1024px) {
    margin-left: 70px;
    margin-top: 35px;
  }

  @media (max-width: 768px) {
    margin-left: 30px;
    margin-top: 85px;
  }

  @media (max-width: 560px) {
    margin-left: 30px;
    margin-top: 35px;
    width: 241px;
    height: 118px;
  }

  @media (max-width: 375px) {
    margin-left: 20px;
    margin-top: 84px;
  }
`;

const BoxBanner = styled.div`
  width: 15px;
  height: 187px;
  background-color: #e45137;

  @media (max-width: 560px) {
    width: 10px;
    height: 105px;
  }
`;

const BoxOrangeLeft = styled(BoxBanner)`
  margin-left: -6.5px;
`;

const BoxOrangeRight = styled(BoxBanner)`
  margin-right: -6.5px;
`;

const TextBox = styled.span`
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  color: #0056a1;
  padding: 0 24px;

  @media (max-width: 560px) {
    font-size: 18px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    padding: 0 20px;
  }
`;

const OrangeText = styled.span`
  font-family: "Titillium Web";
  font-size: 38px;
  color: #e45137;
  @media (max-width: 560px) {
    font-size: 21px;
  }
`;

const TextTitle = styled.span`
  font-family: "Titillium Web";
  font-size: 38px;
  color: #0056a1;

  @media (max-width: 560px) {
    font-size: 21px;
  }
`;

const SubTitle = styled.span`
  font-family: "Titillium Web";
  font-weight: 300;
  font-size: 24px;
  line-height: 119%;
  font-style: normal;
  color: #0056a1;

  @media (max-width: 560px) {
    font-size: 14px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

const SecondLine = styled.div`
  display: grid;
  margin-top: 10px;
`;

const MyAnim = styled(ScrollAnimation)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 427px;
  height: 209px;
  background-color: #fff;
  position: absolute;
  margin-top: 70px;
  z-index: 99;

  @media (max-width: 1024px) {
    margin-left: 70px;
    margin-top: 35px;
  }

  @media (max-width: 768px) {
    margin-left: 30px;
    margin-top: 85px;
  }

  @media (max-width: 560px) {
    margin-left: 30px;
    margin-top: 35px;
    width: 241px;
    height: 118px;
  }

  @media (max-width: 375px) {
    margin-left: 20px;
    margin-top: 84px;
  }
`;
