import React from "react";
import styled from "styled-components";

import VideoImg from "../../assets/images/frame_video.jpg";
import PlayIcon from "../../assets/images/play-button.svg";

const Video = (props) => {
  return (
    <Container>
      {props.lang === "PT" ? (
        <Player
          frameBorder={"0"}
          allow={
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          }
          allowFullScreen
          src={"https://www.youtube.com/embed/3ri-FSSjKb4"}
        ></Player>
      ) : (
        <Player
          frameBorder={"0"}
          allow={
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          }
          allowFullScreen
          src={"https://www.youtube.com/embed/so4vTK1WSaA"}
        ></Player>
      )}
    </Container>
  );
};

export default Video;

const Container = styled.div`
  max-width: 954px;
  margin: 0 auto;
`;
const PlayLogo = styled.img`
  width: 20%;
  position: absolute;
  top: 36%;
  left: 40%;
`;
const LogoImage = styled.img`
  width: 954px;
  height: auto;

  @media (max-width: 1024px) {
    width: auto;
  }

  @media (max-width: 768px) {
    width: 96%;
    height: auto;
    margin: auto;
    /* height: 409px; */
  }

  @media (max-width: 425px) {
    width: 335px;
    height: 188px;
    margin: auto;
  }
`;
const Player = styled.iframe`
  width: 954px;
  height: 577px;

  @media (max-width: 768px) {
    width: 720px;
    height: 446px;
    margin: auto;
  }
  @media (max-width: 560px) {
    width: 540px;
    height: 366px;
  }
  @media (max-width: 425px) {
    width: 335px;
    height: 188px;
  }
  @media (max-width: 320px) {
    width: 96%;
    height: 158px;
  }
`;
