import React from "react";
import styled from "styled-components";

const InspiredOfFutureDown = (props) => {
  return (
    <Container>
      <Title
        dangerouslySetInnerHTML={{
          __html: props.lang.TitleInspiredOfFutureDown,
        }}
      />
      <Content
        dangerouslySetInnerHTML={{
          __html: props.lang.InspiredOfFutureDown,
        }}
      />
    </Container>
  );
};

export default InspiredOfFutureDown;

const Container = styled.section`
  max-width: 954px;
  width: 96%;
  padding: 55px 0px 31px 0;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.queries.md}) {
    margin: 0 1rem;
  }

  @media (max-width: 426px) {
    img {
      height: 35px;
      width: 4px;
    }
  }
`;

const Title = styled.div`
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 45px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #0056a1;
  margin-bottom: 55px;
  padding: 0 20px;
  border-left: 9px solid #e45137; /* Substituído por <img src={barra}/> em outros arquivos */
  border-right: 9px solid #e45137; /* Substituído por <img src={barra}/> em outros arquivos */
  height: 38px;
  display: inline-flex;

  span {
    color: #e45137;
  }

  @media (max-width: 426px) {
    font-size: 20px;
  }
`;
const Content = styled.div`
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #2a3552;
    margin-bottom: 23px;
    width: 98%;
  }
`;
