import React from "react";
import styled from "styled-components";

import Barra from "../../assets/images/barra.svg";

import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const NewBrandNewWay = (props) => {
  return (
    <Wrapper>
      <ContainerTitle>
        {/* <img src={Barra} /> */}
        <Title1
          dangerouslySetInnerHTML={{
            __html: props.lang.NewBrandNewWayTitle1,
          }}
        />
        {/* <Title2
          dangerouslySetInnerHTML={{
            __html: props.lang.NewBrandNewWayTitle2,
          }}
        /> */}
        {/* <img src={Barra} /> */}
      </ContainerTitle>
      <Container
        dangerouslySetInnerHTML={{
          __html: props.lang.NewBrandNewWayText,
        }}
      />
    </Wrapper>
  );
};

export default NewBrandNewWay;

const Container = styled.section`
  padding-top: 55px;
  margin: 0 auto;

  p:last-child {
    margin-bottom: 0;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: #2a3552;
    margin-bottom: 23px;
  }
`;

const Wrapper = styled.div`
  max-width: 954px;
  width: 98%;
  padding: 55px 0px;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.queries.md}) {
    margin: 0 1rem;
  }
`;

const Title1 = styled.span`
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 119.1%;
  color: #0056a1;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 20px;
  border-left: 9px solid #e45137;
  border-right: 9px solid #e45137;
  height: 38px;

  span {
    color: #e45137;
    margin-left: 0px;
  }

  @media (max-width: 700px) {
    font-size: 28px;
    margin: 0;
  }
  @media (max-width: 426px) {
    font-size: 20px;
  }
  @media (max-width: 376px) {
    padding: 0 10px;
    margin: 0;
  }
  @media (max-width: 320px) {
    font-size: 18px;
    padding: 0 6px;
  }
`;

const Title2 = styled(Title1)`
  font-family: "Titillium Web";
  color: #e45137;
  margin: 0;
  margin-left: 5px;
  padding: 0;
`;

const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 426px) {
    img {
      height: 35px;
      width: 4px;
    }
  }
`;
