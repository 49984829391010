import React from "react";
import styled from "styled-components";

import Barra from "../../assets/images/barra.svg";

import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const OurPurpose = (props) => {
  return (
    <Wrapper>
      <ContainerTitle>
        <Title1
          dangerouslySetInnerHTML={{
            __html: props.lang.OurPurposeTitle1,
          }}
        />
        <Title2
          dangerouslySetInnerHTML={{
            __html: props.lang.OurPurposeTitle2,
          }}
        />
      </ContainerTitle>
      <Container
        dangerouslySetInnerHTML={{
          __html: props.lang.OurPurposeText,
        }}
      />
    </Wrapper>
  );
};

export default OurPurpose;

const Container = styled.section`
  padding: 54px 0px;
  margin: 0 auto;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #0055a0;
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  max-width: 954px;
  width: 98%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 96%;
  }

  @media (max-width: ${(props) => props.theme.queries.md}) {
    margin: 0 1rem;
  }
  @media (max-width: ${(props) => props.theme.queries.md}) {
    width: auto;
  }
`;

const Title1 = styled.span`
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  color: #0056a1;

  @media (max-width: 426px) {
    font-size: 20px;
  }
`;

const Title2 = styled(Title1)`
  font-family: "Titillium Web";
  color: #e45137;
`;

const ContainerTitle = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-left: 9px solid #e45137;
  border-right: 9px solid #e45137;
  height: 38px;

  @media (max-width: 426px) {
    img {
      height: 35px;
      width: 4px;
    }
  }
`;
