import React, { useEffect } from "react";

//components
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

import portuguese from "../json/pt-br.json";
import english from "../json/en-US.json";
import spanish from "../json/es-es.json";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const Layout = (props) => {
  const location = useLocation();
  const [lang, setLang] = useState(portuguese);

  useEffect(() => {
    const query = queryString.parse(location.search);
    switch (query.l) {
      case "PT":
        setLang(portuguese);
        break;
      case "EN":
        setLang(english);
        break;
      case "ES":
        setLang(spanish);
        break;
    }
  }, [location]);

  return (
    <div style={{ fontFamily: "Open Sans" }}>
      <Navbar lang={lang} />
      {props.children}
      <Footer lang={lang} />
    </div>
  );
};
