import React from "react";
import styled from "styled-components";

import Logo from "../assets/images/logo.png";
import CustomSelect from "./CustomSelect";

const Navbar = (props) => {
  return (
    <Container>
      <a href={props.lang.LinkSite} target="_blank">
        <LogoImage src={Logo} alt="" />
      </a>
      <CustomSelect />
    </Container>
  );
};

export default Navbar;

const Container = styled.div`
  max-width: ${(props) => props.theme.queries.xl};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0px;
  background-color: #fff;
  max-width: 954px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 38px 1rem;
  }
`;

const LogoImage = styled.img`
  width: auto;
  height: auto;

  @media (max-width: 768px) {
    width: 168px;
    height: 20px;
  }
`;
