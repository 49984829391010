import styled from "styled-components";

//assets
import logotype from "../../assets/images/logo-white.png";
import bar from "../../assets/images/bar.svg";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.css";

const FeatureCard = ({ title, description, classname = "" }) => (
  <Card>
    <img src={bar} />
    <CardContent>
      <CardTitle className={classname}>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContent>
  </Card>
);

const SectionMainFeatures = (props) => {
  return (
    <Container>
      <Wrap>
        <Title
          dangerouslySetInnerHTML={{
            __html: props.lang.MainFeatures,
          }}
        />

        <GridMobileReverse>
          {/* <FeatureCard
            classname="pt-4 md:pt-0"
            title="Tipografia"
            description="Inspirada em nossos pilares sólidos, na precisão e na capacidade técnica."
          /> */}
          <Card>
            <CardContent>
              <ScrollAnimation animateIn="animate__fadeIn">
                <CardTitle
                  dangerouslySetInnerHTML={{
                    __html: props.lang.Tipografia,
                  }}
                />
                <CardDescription
                  dangerouslySetInnerHTML={{
                    __html: props.lang.TipografiaTexto,
                  }}
                />
              </ScrollAnimation>
            </CardContent>
          </Card>
          {/* <FeatureCard
            title="Foco"
            description="O círculo central representa as pessoas, que são a nossa verdadeira força interior. Sua posição central mostra nosso foco e determinação."
          /> */}
          <Card>
            <CardContent>
              <ScrollAnimation animateIn="animate__fadeIn">
                <CardTitle
                  dangerouslySetInnerHTML={{
                    __html: props.lang.Foco,
                  }}
                />
                <CardDescription
                  dangerouslySetInnerHTML={{
                    __html: props.lang.FocoTexto,
                  }}
                />
              </ScrollAnimation>
            </CardContent>
          </Card>
          {/* <FeatureCard
            title="Modularidade"
            description="Todas as letras derivam
            de círculos ou retas, o que confere modularidade à marca, remetendo à geometria e engenharia."
          /> */}
          <Card>
            <CardContent>
              <ScrollAnimation animateIn="animate__fadeIn">
                <CardTitle
                  dangerouslySetInnerHTML={{
                    __html: props.lang.Modularidade,
                  }}
                />
                <CardDescription
                  dangerouslySetInnerHTML={{
                    __html: props.lang.ModularidadeTexto,
                  }}
                />
              </ScrollAnimation>
            </CardContent>
          </Card>
        </GridMobileReverse>

        <Logo src={logotype} />

        <Grid>
          {/* <FeatureCard
            title="Direção"
            description="As letras “N” e “V” estilizadas são inspiradas em setas, símbolos de direção."
          /> */}
          <Card>
            <CardAlign>
              <CardContent>
                <ScrollAnimation animateIn="animate__fadeIn">
                  <CardTitle
                    dangerouslySetInnerHTML={{
                      __html: props.lang.Direcao,
                    }}
                  />
                  <CardDescription
                    dangerouslySetInnerHTML={{
                      __html: props.lang.DirecaoTexto,
                    }}
                  />
                </ScrollAnimation>
              </CardContent>
            </CardAlign>
          </Card>
          {/* <FeatureCard
            classname="pt-8 md:pt-0"
            title="Conexão"
            description="O toque das linhas que estruturam nossas letras representa as novas conexões com a sociedade."
          /> */}
          <Card>
            <CardAlign>
              <CardContent>
                <ScrollAnimation animateIn="animate__fadeIn">
                  <CardTitle
                    dangerouslySetInnerHTML={{
                      __html: props.lang.Conexao,
                    }}
                  />
                  <CardDescription
                    dangerouslySetInnerHTML={{
                      __html: props.lang.ConexaoTexto,
                    }}
                  />
                </ScrollAnimation>
              </CardContent>
            </CardAlign>
          </Card>
          {/* <FeatureCard
            classname="pt-8 md:pt-0"
            title="Cores"
            description="O azul simboliza tecnologia, confiança e segurança. O vermelho coral representa energia, força, vitalidade  e coragem."
          /> */}
          <Card>
            <CardAlign>
              <CardContent>
                <ScrollAnimation animateIn="animate__fadeIn">
                  <CardTitle
                    dangerouslySetInnerHTML={{
                      __html: props.lang.Cores,
                    }}
                  />
                  <CardDescription
                    dangerouslySetInnerHTML={{
                      __html: props.lang.CoresTexto,
                    }}
                  />
                </ScrollAnimation>
              </CardContent>
            </CardAlign>
          </Card>
        </Grid>
      </Wrap>
    </Container>
  );
};

export default SectionMainFeatures;

const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  background-color: #0053a2;
  padding: 60px 16px 90px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${(props) => props.theme.queries.md}) {
    max-width: ${(props) => props.theme.queries.md};
    padding: 30px 50px;
    width: auto;
    margin: 0 1rem;
  }

  @media (max-width: ${(props) => props.theme.queries.sm}) {
    height: 100%;
    padding: 30px 26px;
  }
`;

const Wrap = styled.div`
  max-width: 954px;
  margin: 0 auto;
  width: 100%;
`;

const Title = styled.h2`
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 2.125rem;
  color: #fff;

  @media (max-width: ${(props) => props.theme.queries.sm}) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

const Grid = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 13px;
  margin-left: 2rem;

  /* @media (max-width: ${(props) => props.theme.queries.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    margin-left: 0rem;
  } */

  @media (max-width: ${(props) => props.theme.queries.md}) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-between;
    grid-gap: 0;
    margin-left: 0rem;
  }

  @media (max-width: ${(props) => props.theme.queries.sm}) {
    border-left: 1px solid #768fab;
    margin-left: 2rem;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
    height: 406px;
  }
`;

const GridMobileReverse = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 13px;
  margin-left: 2rem;

  /* @media (max-width: ${(props) => props.theme.queries.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    margin-left: 0rem;
  } */

  @media (max-width: ${(props) => props.theme.queries.md}) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-between;
    grid-gap: 0;
    margin-left: 0rem;
  }

  @media (max-width: ${(props) => props.theme.queries.sm}) {
    border-left: 1px solid #768fab;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-left: 2rem;
    grid-gap: 0px;
    height: 406px;
  }
`;

const Logo = styled.img`
  margin-top: 30px;
  width: auto;
  max-width: 777px;
  height: 100px;
  margin-left: 2rem;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.queries.md}) {
    max-width: auto;
    margin-left: 0rem;
    width: 100%;
    height: auto;
  }
`;

//CARD

const Card = styled.div`
  height: 135px;
  max-width: 200px;
  display: flex;
  border-left: 1px solid #7b8baa;

  img {
    width: 1px;
    margin-right: 2px;
  }

  @media (max-width: ${(props) => props.theme.queries.sm}) {
    max-width: 250px;
  }

  @media (max-width: ${(props) => props.theme.queries.sm}) {
    width: 201px;
    height: 100%;

    img {
      display: none;
    }
  }
`;
const CardAlign = styled.div`
  @media (max-width: 426px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const CardContent = styled.div`
  padding-left: 0.687rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 5px;
`;

const CardTitle = styled.div`
  color: #e9533f;
  font-weight: 600;
  font-size: 1.187rem;
  line-height: 22, 78px;
`;

const CardDescription = styled.p`
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  margin-top: 0.5rem;
  font-family: "Open Sans";
`;
