import React from "react";
import styled from "styled-components";

import Barra from "../../assets/images/barra.svg";

import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const OurVision = (props) => {
  return (
    <Wrapper>
      <ContainerTitle>
        {/* <img src={Barra} /> */}
        <Title1
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionTitle1,
          }}
        />
        <Title2
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionTitle2,
          }}
        />
        {/* <img src={Barra} /> */}
      </ContainerTitle>
      <Container
        dangerouslySetInnerHTML={{
          __html: props.lang.OurVisionText,
        }}
      />
      <ContainerTitle>
        <Title3
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionTitle3,
          }}
        />
      </ContainerTitle>
      <List>
        <p
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTitle,
          }}
        />
        <ListItem
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTextOne,
          }}
        />
        <ListItem
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTextTwo,
          }}
        />
        <ListItem
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTextThree,
          }}
        />
        <ListItem
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTextFour,
          }}
        />
        <ListItem
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTextFive,
          }}
        />
        <ListItem
          dangerouslySetInnerHTML={{
            __html: props.lang.OurVisionListTextSix,
          }}
        />
      </List>

      <ToKnowMore
        dangerouslySetInnerHTML={{
          __html: props.lang.OurVisionTextSeeMore,
        }}
      />
    </Wrapper>
  );
};

export default OurVision;

const Container = styled.section`
  padding: 54px 0 30px 0;
  margin: 0 auto;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #0055a0;
    margin-bottom: 23px;
  }
`;

const Wrapper = styled.div`
  max-width: 954px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 96%;
  }
  @media (max-width: ${(props) => props.theme.queries.md}) {
    margin: 0 1rem;
  }
  @media (max-width: ${(props) => props.theme.queries.md}) {
    width: auto;
  }
`;

const Title1 = styled.span`
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  color: #0056a1;

  @media (max-width: 426px) {
    font-size: 20px;
  }
`;

const Title2 = styled(Title1)`
  font-family: "Titillium Web";
  color: #e45137;
`;
const Title3 = styled(Title1)`
  font-family: "Titillium Web";
  color: #0056a1;
  span {
    color: #e45137;
  }
`;

const ContainerTitle = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-left: 9px solid #e45137;
  border-right: 9px solid #e45137;
  height: 38px;

  @media (max-width: 426px) {
    img {
      height: 35px;
      width: 4px;
    }
  }
`;

const List = styled.ul`
  list-style: disc;
  color: ${(props) => props.theme.color.default};
  padding-top: 3rem;

  p {
    font-size: 18px;
  }
`;

const ListItem = styled.li`
  padding-left: 0.5rem;
  margin-top: 20px;
  margin-left: 2rem;
  font-size: 18px;
`;

const ToKnowMore = styled.div`
  margin-top: 80px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #0055a0;
  margin-bottom: 23px;

  a {
    color: #e9533f;
  }
`;
