import React from "react";
import styled from "styled-components";

const InspiredOfFuture = (props) => {
  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: props.lang.InspiredOfFuture,
      }}
    />
  );
};

export default InspiredOfFuture;

const Container = styled.section`
  max-width: 954px;
  width: 98%;
  padding: 55px 0px;
  margin: 0 auto;

  p:last-child {
    margin-bottom: 0;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: ${(props) => props.theme.color.default};
    margin-bottom: 23px;
  }

  @media (max-width: ${(props) => props.theme.queries.md}) {
    margin: 0 1rem;
  }
  @media (max-width: 1000px) {
    width: 96%;
  }
`;

const Title = styled.p``;
