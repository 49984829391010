export const theme = {
  color: {
    default: "#2A3552",
  },
  boxShadow: "",
  queries: {
    i5: "320px",
    sm: "440px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
  font: "Open-Sans, sans-serif",
};
